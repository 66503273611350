<template>
  <div style="padding:0;margin:0;">
    <div style="margin-bottom: -100px !important">
      <div class="row headerblock">
        <div class="col-4 col-s-6">
          <V-logo style="margin-left:4px" />
        </div>
        <div class="col-8 col-s-6" style="padding: 0">
          <ul v-if="minimenu == false" class="navblock" style="float: right">
            <li v-for="nav in menulist" :key="nav.index">
              <v-link :href="nav.menuUrl">{{ nav.menuTitle }}</v-link>
            </li>
            <!-- <li><a href="https://www.zicacloud.com/" target="blank">关于我们</a></li> -->
            <li>
              <a
                href="https://pet.xinchongjia.com/regs"
                style="margin-top: 10px; padding: 4px 16px"
                class="button button-outline"
                type=""
                >免费注册</a
              >
            </li>
            <li>
              <a href="https://pet.xinchongjia.com/login"
                style="margin-top: 10px; padding: 4px 16px"
                class="button button-outline"
                type=""
                >商家登录</a
              >
            </li>
          </ul>
          <div>
            <div v-if="minimenu" class="mininavIcon white" @click="showMiniNav">
              <i :class="menuiconclass"></i>
            </div>
            <div v-if="mininavblock" class="mininavBlock">
              <ul class="mininav">
                <li v-for="nav in menulist" :key="nav.index">
                  <v-link :href="nav.menuUrl">{{ nav.menuTitle }}</v-link>
                </li>
                <li>
                  <a href="https://pet.xinchongjia.com/regs" style="color: #ff700b">免费注册</a>
                </li>
                <li>
                  <a href="https://pet.xinchongjia.com/login" style="color: #ff700b">商家登录</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页面跳转 -->
    <slot></slot>
    <!-- footer -->
    <Footer />
  </div>
</template>

<script>
import VLink from "../components/VLink.vue";
import Footer from "../components/footer.vue";
import { MenuInfo } from "../assets/common/config.js";
import VLogo from "../components/logo.vue";
let menu = new MenuInfo();
export default {
  data() {
    return {
      menulist: menu.menulist,
      screenWidth: "",
      minimenu: false,
      mininavblock: false,
      menuiconclass:'iconfont iconcaidan icon-xs'
    };
  },
  components: {
    VLink,
    VLogo,
    Footer,
  },
  methods: {
    showMiniNav() {
      if (this.mininavblock == false) {
        this.mininavblock = true;
        this.menuiconclass = 'iconfont iconclose icon-xs'
      } else {
        this.mininavblock = false;
        this.menuiconclass = 'iconfont iconcaidan icon-xs'
        
      }
    },
  },
  created() {
    this.screenWidth = document.body.clientWidth;
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth <= 850) {
        this.minimenu = true;
      } else {
        this.minimenu = false;
      }
      // console.log("this.screenWidth", this.screenWidth,"minimenu",this.minimenu);
    },
  },
};
</script>

