<template>
  <div class="row">
    <div class="col-4 col-s-12" v-for="list in card" :key="list.index">
      <div class="carditem">
        <i v-if="showIcon  && !list.qrcode" class="icon-sm icon-primary" :class="list.icon"></i>
        <img
          v-if="showImg"
          :src="getImgurl(list.cardimg)"
          width="100"
          height="100"
        />
        <img
          v-if="showIcon  && list.qrcode"
          :src="getImgurl(list.cardimg)"
          width="100"
          height="100"
        />
        <h3>{{ list.title }}</h3>
        <p class="text-align-left">{{ list.content }}</p>
        <p style="padding-top: 10px">
          <a v-if="list.button" :href="list.href" class="primay font-size-md">{{
            list.button
          }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
     
    };
  },
  //部件
  components: {},
  //静态
  props: {
    showIcon: Boolean,
    showImg: Boolean,
    card: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {},
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    getImgurl(src) {
      if (src.length > 0) {
        if (src.indexOf("http") == 0) {
          return src;
        }
        if (src.indexOf("http") == -1) {
          return require("@/" + src);
        }
      } else {
        return src;
      }
    },
  },
  //请求数据
  created() {
  },
  mounted() {},
};
</script>

<style scoped>
/* cards css */
.carditem {
  border-radius: 0.5rem;
  margin: 20px 10px !important;
  padding: 30px 20px 20px 20px !important;
  /* border: solid 1px var(--colorCBCBCB); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  text-align: center !important;
  z-index: 999;
  background-color: var(--colorfff);
  min-height: 280px !important;
  max-height: 280px;
  
}
.carditem p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
