<template>
  <main-layout>
    <!-- <div class="bannerblock banner-bg" style="z-index: -999; ">banner</div> -->
    <Banner :banners="banners" />

    <div class="mainblock" style="margin-top:-100px !important; z-index: 99;">
      <Card :card="cardA" :showIcon="true"/>
      

      <div class="padding-md text-align-center">
        <a href="https://pet.xinchongjia.com/regs" class="button primary button-s-md button-md" type="">
          免费试用3个月
        </a>
        <h2>{{ titleA.title }}</h2>
        <p>{{ titleA.content }}</p>
      </div>

      
      <Card :card="cardB" :showIcon="true" />

      <div class="padding-md text-align-center">
        <h2>{{ titleB.title }}</h2>
        <p>{{ titleB.content }}</p>
      </div>
      <Tab :tabInfo="tablist" tabStyle="vertical" />

      <div class="padding-md text-align-center row">
        <h2>{{ titleD.title }}</h2>
        <div class="col-4 col-s-12" v-for="list in datas" :key="list.index">
          <h1 class="primay">{{ list.sum }}</h1>
          <p>{{ list.name }}</p>
        </div>
      </div>
      <div class="padding-md text-align-center row"><h2>{{ titleE.title }}</h2></div>
      <Card :card="cardC" :showImg="true" />

      <div>
        <h3>常见问题</h3>
        <Collapes :collapsList="collapesListA" />
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Card from "../components/card.vue"
import Tab from "../components/tab.vue";
import Collapes from "../components/collapes.vue";
import Banner from "../components/banner.vue";
import { DataInit } from "../assets/common/config.js";

export default {
  name: "home",
  data() {
    return {
      banners: [],
      cardA: [],
      cardB: [],
      titleA: [],
      titleB: [],
      titleD: [],
      tablist: [],
      collapesListA: [],
      datas: [],
     
    };
  },
  components: {
    MainLayout,
    Tab,
    Collapes,
    Banner,
    Card
  },
  created() {
    this.screenWidth = document.body.clientWidth;
    DataInit.getData(this);
  },
  methods: {},
  mounted() {
   
  },
  watch: {
    
  },
};
</script>
