<template>
  <div class="banner-bg" :style="bannerbg" ref="bannerwidth">
    <i
      v-if="arrows"
      @click="prev"
      class="icon-xs iconfont iconleft white pointer"
      style="position: absolute; left: 50px; top: 250px"
    ></i>
    <i
      v-if="arrows"
      @click="next"
      class="icon-xs iconfont iconright white pointer"
      style="position: absolute; right: 50px; top: 250px"
    ></i>
    <div class="row bannerblock">
      <div :class="setbannerStyle" style="margin-top:70px !important; ">
        <!-- <img src="../assets/banners/homebanner1.png" alt=""> -->
        <h1>{{ bannerTitle }}</h1>
        <p class="white">{{ bannerContent }}</p>
      </div>
      <div v-if="isShowbannerImg" class="col-6 col-s-12 text-align-center " style="margin-top:100px !important; " >
        <img v-if="banners[bannerIndex].bannerImg.length != 0 " :src="bannerImg" width="360" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ss: require("@/assets/banners/homebannerbg1.png"),
      bannerIndex: 0,
      bannerwidth: 0,
      arrows: false,
      bannerbg: {
        background: "",
        backgroundColor: "#D45902",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPosition: "left top",
        backgroundAttachment: "fixed",
        transitionDuration: 0.4 + "s",
        Zindex: -999,
      },
      bannerTitle: "",
      bannerContent: "",
      bannerImg: "",
      bannerStyle: "",
      isShowbannerImg: false
    };
  },
  //部件
  components: {},
  //静态
  props: {
    banners: {
      type: Array,
      default() {
        return [
          
        ];
      },
    },
  },
  computed: {
    setbannerStyle() {
      if (this.bannerImg == "") {
        return "col-12 col-s-12 white text-align-center";
      } else {
        return "col-6 col-s-12 white text-align-left";
      }
    },
  },
  
  methods: {
    getImgurl(src) {
      
      if (src.length > 0) {
        if (src.indexOf("http") == 0) {
          return src;
        }
        if (src.indexOf("http") == -1) {
          return require("@/" + src);
        }
      } else {
        return src;
      }
    },
    bannerInit() {
      this.bannerbg.background =
        "url(" + this.getImgurl(this.banners[this.bannerIndex].bgImg) + ")";
      this.bannerTitle = this.banners[this.bannerIndex].title;
      this.bannerContent = this.banners[this.bannerIndex].content;
      this.bannerImg = this.getImgurl(this.banners[this.bannerIndex].bannerImg);
    },
    next() {
      
      if (this.bannerIndex == this.banners.length - 1) {
        this.bannerIndex = 0;
      } else {
        this.bannerIndex++;
      }
      this.bannerInit();
    },
    prev() {
      if (this.bannerIndex == 0) {
        this.bannerIndex = this.banners.length - 1;
      } else {
        this.bannerIndex--;
      }
      this.bannerInit();
    },
  },
  watch: {
    bannerwidth(val) {
      this.bannerwidth = val;
      if (this.bannerwidth <= 760) {
        this.arrows = false;
        this.bannerbg.backgroundSize = "auto"
        this.isShowbannerImg = false
      }else{
        
        this.bannerbg.backgroundSize = "auto 100%"
  
        this.isShowbannerImg = true
        if( this.banners.length >= 2){
            this.arrows = true;
        }else{
            this.arrows = false;
        }
      }
    },
  },
  //请求数据
  created() {
    this.bannerInit();
    if (this.banners.length > 1) {
      setInterval(this.next, 8000);
    }
    this.bannerwidth = document.body.clientWidth;
  },
  mounted() {
    
  },
};
</script>

<style scoped>
.bannerblock img {
  transition-duration: 0.5s;
}

.banner-bg {
    background-color: var(--colorpopaul);
}

</style>
